import { ToastNotification } from 'ts/commons/ToastNotification';
import { tsdom } from 'ts/commons/tsdom';

/** Utility methods for handling the clipboard (e.g. to copy commit hashes). */
export class ClipboardUtils {
	/**
	 * Enables the 'copy-to-clipboard' functionality for the element with the given DOM id.
	 *
	 * @see #hookCopyToClipboardForElement
	 */
	public static hookCopyToClipboardForElementWithId(elementId: string): void {
		ClipboardUtils.hookCopyToClipboardForElement(tsdom.getElementById(elementId));
	}

	/**
	 * Enables the 'copy-to-clipboard' functionality for the given element. This element should specify the content to
	 * copy to the clipboard via the data-clipboard-text attribute inside the HTML or refers to another element's value
	 * via data-clipboard-target.
	 */
	public static hookCopyToClipboardForElement(element: HTMLElement): void {
		element.addEventListener('click', () => {
			const targetSelector = element.dataset.clipboardTarget;
			if (targetSelector != null) {
				const value = tsdom.getValue(document.querySelector(targetSelector)!);
				void ClipboardUtils.copyTextToClipboard(value);
			} else {
				const text = element.dataset.clipboardText!;
				void ClipboardUtils.copyTextToClipboard(text);
			}
		});
	}

	/**
	 * Copies the given text to the clipboard. NOTE: This only works when invoked after a user interaction (e.g. a mouse
	 * down event).
	 */
	public static async copyTextToClipboard(text: string): Promise<void> {
		try {
			return await navigator.clipboard.writeText(text);
		} catch (e) {
			ClipboardUtils.copyTextToClipboardWithFakeElement(text);
		}
	}

	/**
	 * Fallback for copying the given text to clipboard, as the default copyTextToClipboard only works for secure
	 * contexts. As a fallback we use the deprecated execCommand. If it still fails, an error Toast is displayed.
	 */
	private static copyTextToClipboardWithFakeElement(text: string): void {
		const tmpNode = document.createElement('div');
		tmpNode.style.color = 'rgba(0, 0, 0, 0)';

		document.body.appendChild(tmpNode);
		tmpNode.innerText = text;
		try {
			const selection = window.getSelection()!;
			const range = document.createRange();
			range.selectNode(tmpNode);
			selection.removeAllRanges();
			selection.addRange(range);
			document.execCommand('copy');
		} catch (e) {
			console.error('Error copying text to clipboard: ' + e);
			ToastNotification.error(
				'Copying to the clipboard was not successful. A possible reason is that it was blocked by your browser as the connection to your Teamscale instance is not secure.'
			);
		} finally {
			setTimeout(() => document.body.removeChild(tmpNode), 0);
		}
	}
}
